<template>
  <footer class="p-10">
    <ul class="flex flex-wrap items-center justify-center gap-8">
      <li
        v-for="link in links"
        :key="link.link"
      >
        <NuxtLink
          :to="link.to"
          class="text-sm"
        >
          {{ link.label }}
        </NuxtLink>
      </li>
    </ul>
    <div class="mt-6 text-center">
      <span class="text-xs opacity-80">Copyright © {{ year }} | All rights reserved</span>
    </div>
  </footer>
</template>

<script setup>
const year = new Date().getFullYear()
const links = [
  {
    to: 'https://cgworks.jp/company',
    label: '運営会社情報',
  },
  {
    to: '/privacy',
    label: 'プライバシーポリシー',
  },
  {
    to: '/term',
    label: '利用規約',
  },
  {
    to: '/sctl',
    label: '特定商取引法に基づく表記',
  },
]
</script>
